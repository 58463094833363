import React from "react";
import { graphql } from 'gatsby';
import Img from "gatsby-image";
import ReactMarkdown from 'react-markdown';
import Layout from '../js/components/Layout';
import SEO from '../components/SEO';

const getLoc = project => {
  if (project.location || project.country) {
    return `${project.location}, ${project.country}`;
  } else if (project.location) {
    return project.location;
  } else if (project.country) {
    return project.country;
  }
  return null;
}

const Project = ({data}) => {
  const project = data.allProject.edges[0].node;
  const location = getLoc(project);

  const title = `${project.name} | Green Culture Studio Landscape Design`;

  return (
    <Layout>
      <SEO title={title} description={project.description} fullImageUrl={project.project_images[0].url} />

      <div className="row justify-content-center project-container">
        <div className="col-lg-4 col-md-8 col-sm-10 col-12">
          <div className="sticky-top sticky-offset">
            <div className="project-info">
              <h1>{project.name}</h1>
              {location && <h2>{location}</h2>}
            </div>
            {project.rich_description && <ReactMarkdown children={project.rich_description} />}

          </div>
        </div>
        <div className="col-lg-7 col-md-8 col-sm-10 col-12">
          {
            project.project_images.map(
              image => (
                <Img
                  className="project-image"
                  fluid={
                    image.img.childImageSharp.fluid
                  }
                  alt={image.alt}
                  key={image.url}
                />
              ),
            )
          }
        </div>
      </div>
    </Layout>
  );
}

export const query = graphql`
query ProjectPage($name: String){
  allProject(filter: {name: {eq: $name}}) {
    edges {
      node {
        id
        name
        location
        country
        slug
        rich_description
        description
        priority
        project_images {
          url
          alt
          img {
            childImageSharp {
              fluid(sizes: """
                (max-width: 300px) 200px,
                (max-width: 600px) 400px,
                (max-width: 900px) 600px,
                (max-width: 1200px) 600px,
                (max-width: 1800px) 900px,
                1500px""", 
                quality: 95, fit: COVER
              ) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  }
}`;

export default Project;
